body {
    -webkit-font-smoothing: antialiased;
    font-family: Averta, Helvetica, Arial, sans-serif;
}





li.slide div {

    height: 700px;

}

li.slide div img {

    object-fit: cover;
    object-position: top;

}

.loading_logo_animation {
    animation: mymove 1.2s infinite;
    animation-timing-function: linear;
}
@keyframes mymove {
    0% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
}